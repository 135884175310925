import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “Reserved this place for my son&#39;s 4th birthday. He and his friend had such a good time. So many options to keep kids busy and tired on the way home.”
                            </p>
                            <p className="overview">Ricky B.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “HIGHLY RECOMMEND THIS PLACE! We had my niece’s birthday party here and it was FANTASTIC! The kids had so much fun and the staff was friendly and accommodating.”
                            </p>
                            <p className="overview">Jen W.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Very awesome place for kids, a great selection of different fun and the employees are extremely respectful and friendly. I would definitely recommend this place to anyone who has kids."
                            </p>
                            <p className="overview">
                                Aron L.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “The staff at We Rock the Spectrum show great compassion, patience and care while providing strong structure and fun. Our family is grateful that we found a safe, clean, and unique facility that our son can enjoy and be himself."
                            </p>
                            <p className="overview">
                                Will R.
                            </p>
                        </div>

                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
